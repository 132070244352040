import dayjs from 'dayjs'
export const normalizeToTableEmployee = (serverEmployee) => {
  const qualificationColors = {
    trainee: 'pink--text',
    newbie: 'green--text',
    experienced: 'blue--text',
    pro: 'primary--text',
  }
  const isActiveChipOptions = (isActive) => {
    return isActive
      ? {
          color: 'green-50',
          textColor: 'green-600',
          borderColor: 'green-100',
        }
      : {
          color: 'neutral-50',
          textColor: 'neutral-600',
          borderColor: 'neutral-100',
        }
  }

  const staticUrl = process.env.baseURLStatic

  return {
    uuid: serverEmployee.uuid,
    hire_date: dayjs(serverEmployee.hire_date * 1000).format('DD.MM.YYYY'),
    personal: {
      name: `${serverEmployee.personal.last_name} ${serverEmployee.personal.first_name}`,
      img:
        serverEmployee.personal.img?.path &&
        `${staticUrl}/resize/128x128/${serverEmployee.personal.img.path}`,
      altImg: `${
        serverEmployee.personal?.first_name
          ? serverEmployee.personal?.first_name[0]
          : '-'
      }${
        serverEmployee.personal?.last_name
          ? serverEmployee.personal?.last_name[0]
          : '-'
      }`,
    },
    contact: {
      phone: serverEmployee.contact.phone ?? '-',
      eMail: serverEmployee.contact.eMail ?? '-',
    },
    statuses: {
      is_active: serverEmployee.statuses?.is_active || false,
      is_active_chip_options: isActiveChipOptions(
        serverEmployee.statuses?.is_active
      ),
      is_taxpayer: serverEmployee.statuses?.is_taxpayer || false,
    },
    role: {
      value: serverEmployee.role.value,
      label: serverEmployee.role.label,
    },
    qualification: {
      value: serverEmployee.qualification.value,
      label: serverEmployee.qualification.label,
      color: qualificationColors[serverEmployee.qualification.value],
    },
  }
}
