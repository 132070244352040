import { convertDate } from '~/utils'
import { getProductImg, normalizeUser } from '~/utils/normalize-helpers'
import { normalizeToSingleGcCustomer } from '~/modules/gc-customers/Entities/SingleCustomer'

const normalizeOrder = (serverOrder) => {
  return {
    id: serverOrder.order_id,
    store: serverOrder.store,
    deliveryDate: `${convertDate(serverOrder.date, 'DD-MM-YYYY') || '⎯'} ${
      serverOrder.time ?? '⎯'
    }`,
    currentStatus: {
      label: serverOrder.status?.label ?? '⎯',
      value: serverOrder.status?.value,
    },
    paymentType: {
      label: serverOrder.payment_type?.label ?? '⎯',
      value: serverOrder.payment_type?.value,
    },
    assemblyType: serverOrder.substitute_type,
    storeAddress: serverOrder.store_address,
    deliveryAddress: serverOrder.address,
    comment: serverOrder.comment,
    orderSummary: {
      total: serverOrder.total ?? '⎯',
      totalDiscount: serverOrder.total_discount ?? '⎯',
      factTotal: serverOrder.fact_total ?? '⎯',
      paymentUrl: serverOrder.payment_url ?? '',
      discountPercent:
        ((serverOrder.total_discount / serverOrder.fact_total) * 100).toFixed(
          2
        ) ?? 0,
    },
  }
}

const normalizeStaff = (serverStaff) => {
  return {
    ...serverStaff,
  }
}

const normalizeProduct = (serverProduct) => {
  return serverProduct.map((product) => {
    return {
      ...product,
      image: getProductImg(product.img || null),
    }
  })
}

const normalizeEmployee = (serverEmployee) => {
  const staticUrl = process.env.baseURLStatic
  return {
    uuid: serverEmployee.uuid,
    name: `${serverEmployee.last_name} ${serverEmployee.first_name}`,
    img: serverEmployee.img?.path
      ? `${staticUrl}/resize/512x512/${serverEmployee.img.path}`
      : require('@/assets/images/empty-avatart.png'),
    phone: serverEmployee.phone,
  }
}

export const normalizeToSingleGcOrder = (serverOrder) => {
  const order = normalizeOrder(serverOrder.order)
  const services = serverOrder.services
  const customer = normalizeToSingleGcCustomer(serverOrder.customer)
  const products =
    sortByStatusAndAddDividers(normalizeProduct(serverOrder.products)) || []

  const courier = serverOrder.employee.find(
    (emp) => emp.role.value === 'courier'
  )
  const collector = serverOrder.employee.find(
    (emp) => emp.role.value === 'collector'
  )

  return {
    order,
    staff: {
      courier: courier ? normalizeEmployee(courier) : null,
      collector: collector ? normalizeEmployee(collector) : null,
    },
    customer,
    products,
    services,
  }
}

function sortByStatusAndAddDividers(products) {
  const statusOrder = {
    missing: 1,
    collected: 2,
    changed: 3,
    new: 4,
    clarify: 5,
    plan: 6,
  }

  const statusColors = {
    missing: {
      textColor: 'orange-600--text',
      backgroundColor: 'orange-50',
    },
    collected: {
      textColor: 'green-600--text',
      backgroundColor: 'green-50',
    },
    changed: {
      textColor: 'primary-600--text',
      backgroundColor: 'primary-50',
    },
    new: {
      textColor: 'blue-600--text',
      backgroundColor: 'blue-50',
    },
    plan: {
      textColor: 'blue-600--text',
      backgroundColor: 'blue-50',
    },
  }

  const getStatusOrder = (status) => {
    return statusOrder[status] !== undefined ? statusOrder[status] : Infinity
  }

  const sortedProducts = products.sort((a, b) => {
    const orderA = getStatusOrder(a.status.value)
    const orderB = getStatusOrder(b.status.value)

    if (orderA === orderB) {
      return a.status.value.localeCompare(b.status.value)
    }
    return orderA - orderB
  })

  const productsWithDividers = []
  let currentStatus = null

  sortedProducts.forEach((product) => {
    if (product.status.value !== currentStatus) {
      currentStatus = product.status.value
      productsWithDividers.push({
        name: '',
        sku: '',
        status: {
          value: currentStatus,
          label: product.status.label,
          backgroundColor:
            statusColors[currentStatus]?.backgroundColor || 'neutral-200',
          textColor:
            statusColors[currentStatus]?.textColor || 'neutral-900--text',
        },
        quantity: '',
        price: '',
        result_price: '',
      })
    }
    productsWithDividers.push(product)
  })

  return productsWithDividers
}
