import { render, staticRenderFns } from "./HeaderProfile.vue?vue&type=template&id=7fa38d74&scoped=true&"
import script from "./HeaderProfile.vue?vue&type=script&lang=js&"
export * from "./HeaderProfile.vue?vue&type=script&lang=js&"
import style0 from "./HeaderProfile.vue?vue&type=style&index=0&id=7fa38d74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa38d74",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RostIcon: require('/home/ilya/app/rost_web_frontend/components/rost/icon.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
