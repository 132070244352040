import { isEmptyObject } from '~/utils'

export const product = {
  aboutProduct: {
    sku: null,
    gtin: null,
    name: null,
    description: null,
    productType: null,
    brand: null,
    manufacturer: null,
    images: null,
    tags: [],
  },
  propertyProduct: {
    ntWt: null,
    volume: null,
  },
  compositionProduct: {
    compositionFromPack: null,
    calories: null,
    sugar: null,
    proteins: null,
    fats: null,
    carbohydrates: null,
  },
  propertyPack: {
    packType: null,
  },
}
export const normalizeToSingleProductBranding = (serverProduct) => {
  return {
    manufacturer: serverProduct.manufacturer || null,
    brand: serverProduct.brand || null,
    country: serverProduct.country || null,
    prod_line: serverProduct.prod_line || null,
  }
}
export const normalizeToSingleProduct = (serverProduct) => {
  const res = {
    ...serverProduct,
    product_type: serverProduct.product_type || undefined,
    ingredients: {
      description: serverProduct.ingredients.description || null,
      kcal_100: serverProduct.ingredients.kcal_100 || 0,
      prot_100: serverProduct.ingredients.prot_100 || 0,
      fat_100: serverProduct.ingredients.fat_100 || 0,
      carb_100: serverProduct.ingredients.carb_100 || 0,
      carb_sugar: serverProduct.ingredients.carb_sugar ?? null,
      fat_saturated: serverProduct.ingredients.fat_saturated ?? null,
      fat_mono: serverProduct.ingredients.fat_mono ?? null,
      fat_poly: serverProduct.ingredients.fat_poly ?? null,
      cholesterol: serverProduct.ingredients.cholesterol ?? null,
      cellulose: serverProduct.ingredients.cellulose ?? null,
      nutri_class: serverProduct.ingredients.nutri_class ?? null,
    },
    branding: {
      manufacturer: null,
      brand: null,
      country: null,
      prod_line: null,
    },
    gtin:
      serverProduct.gtin ||
      (serverProduct.masters.length && serverProduct.masters[0]),
  }

  return res
}
